import React from "react";
import { X } from "lucide-react";
import Form from "./form";
import ChatIcon from "./chat";
import { useFaqContext } from "./FaqContext";
import translator from "../Extra/Translation/Translate";

const FloatingActionButton: React.FC = () => {
    const { chatType, setChatType, show } = useFaqContext();
    const { translate } = translator();

    const tabs: Array<"email" | "telegram"> = ["email", "telegram"];

    const toggleForm = () => {
        setChatType(chatType ? undefined : "email");
    };

    console.log({ chatType, show });

    return (
        <div
            className={`fixed bottom-16 ${show ? "z-[10]" : "z-[99]"} right-7 ${
                chatType || show ? "" : "hidden h-[0px]"
            }`}
        >
            <div
                className={`mt-4 bg-white max-h-[70vh] overflow-y-auto rounded-lg shadow-md md:!w-96 w-full md:!mx-0 mx-4 transition-all duration-300 ease-in ${
                    chatType
                        ? "opacity-100 scale-100"
                        : "opacity-0 scale-95 pointer-events-none"
                }`}
            >
                <div className="bg-gradient-to-tl from-kalypay-100 to-yellow-600 p-5 text-white text-lg rounded-t-lg font-medium">
                    <p>{translate("SupportFaq", "Indication")}</p>
                </div>
                {chatType && (
                    <div className="p-5">
                        <div className="relative w-full p-2 rounded-lg bg-gray-200 mb-4">
                            <div className="relative flex w-full">
                                <div
                                    className="absolute top-0 left-0 w-1/2 h-full bg-kalypay-100 rounded-md transition-transform duration-300 ease-in-out"
                                    style={{
                                        transform: `translateX(${
                                            tabs.indexOf(chatType) * 100
                                        }%)`,
                                    }}
                                />
                                {/* {tabs.map((tab) => (
                  <button
                    key={tab}
                    onClick={() => setChatType(tab)}
                    className={`px-4 w-full py-2 rounded-md relative z-10 ${
                      chatType === tab ? "text-white" : "text-gray-700"
                    }`}
                  >
                    {tab.charAt(0).toUpperCase() + tab.slice(1)}
                  </button>
                ))} */}
                            </div>
                        </div>

                        <Form type={chatType} toggleForm={toggleForm} />
                    </div>
                )}
            </div>
            {(chatType || show) && (
                <button
                    onClick={toggleForm}
                    className="bg-kalypay-200 text-white rounded-full w-14 h-14 ml-auto mt-2 flex items-center justify-center shadow-lg transition-transform duration-300 ease-in hover:scale-105"
                >
                    {chatType ? <X /> : <ChatIcon />}
                </button>
            )}
        </div>
    );
};

export default FloatingActionButton;
