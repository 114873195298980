import React, { useEffect, useRef, useState } from "react";
import "animate.css";
import { useAppDispatch } from "../../../globalState/hook";
import translator from "../../../Components/Extra/Translation/Translate";
import {
    ChangeAppLanguage,
    Language,
} from "../../../globalState/reducers/Language.reducer";
import useLanguage from "../../../Services/Hooks/useLanguage";
import UtilsService from "../../../Services/UtilsService";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import Option from "../../../Models/Select";
import { useLocation } from "react-router-dom";

const styleCrypto = {
    width: 25,
    height: 25,
    marginRight: 10,
    borderRadius: "50%",
};
const LanguageSwitcher = ({
    doc,
    show_text = false,
}: {
    doc?: boolean;
    show_text?: boolean;
}) => {
    const dispatch = useAppDispatch();
    const [show, setShow] = useState<boolean>(false);
    const { language } = useLanguage();
    const { translate } = translator();
    const tooltipRef = useRef<HTMLDivElement>(null);
    const location = useLocation();

    const language_list: Array<Option> = [
        {
            label: `${translate("LANGAGE", "english")} (EN)`,
            value: Language.EN,
            image: UtilsService.getPulicImage("/dist/image/language/us.svg"),
            style: styleCrypto,
        },

        {
            label: `${translate("LANGAGE", "french")} (FR)`,
            value: Language.FR,
            image: UtilsService.getPulicImage("/dist/image/language/fr.svg"),
            style: styleCrypto,
        },
        {
            label: `${translate("LANGAGE", "spanish")} (ES)`,
            value: Language.ES,
            image: UtilsService.getPulicImage("/dist/image/language/es.svg"),
            style: styleCrypto,
        },
        {
            label: `${translate("LANGAGE", "german")} (DE)`,
            value: Language.DE,
            image: UtilsService.getPulicImage("/dist/image/language/de.svg"),
            style: styleCrypto,
        },
        {
            label: `${translate("LANGAGE", "turkish")} (TR)`,
            value: Language.TR,
            image: UtilsService.getPulicImage("/dist/image/language/tr.svg"),
            style: styleCrypto,
        },
        {
            label: `${translate("LANGAGE", "chinese")} (ZH)`,
            value: Language.ZH,
            image: UtilsService.getPulicImage("/dist/image/language/zh.svg"),
            style: styleCrypto,
        },
        {
            label: `${translate("LANGAGE", "portuguese")} (PT-BR)`,
            value: Language.PT_BRA,
            image: UtilsService.getPulicImage("/dist/image/language/br.svg"),
            style: styleCrypto,
        },
    ];
    const [search, setSearch] = useState<string>("");
    const [filteredLanguage, setFilteredLanguage] = useState<Option[] | []>(
        language_list,
    );

    const [showBoxShadow, setShowBoxShadow] = useState<boolean>(false);

    const getFilteredLanguage = () => {
        if (search.length <= 0) {
            setFilteredLanguage(language_list);
            return;
        }

        const res = language_list.filter(
            (elt: Option) =>
                elt.value.includes(search.toLowerCase()) ||
                elt.label.toLowerCase().includes(search.toLowerCase()),
        );
        if (res) setFilteredLanguage(res);
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                tooltipRef.current &&
                !tooltipRef.current.contains(event.target as Node)
            ) {
                setShow(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [show]);

    const currentLang = language_list.find((i) => i.value == language);

    const getShowBoxShadow = () => {
        const show_box =
            location.pathname.includes("documentation") ||
            location.pathname.includes("login") ||
            location.pathname.includes("register");

        if (show_box) setShowBoxShadow(show_box);
    };

    useEffect(() => {
        getShowBoxShadow();
    }, [location]);

    useEffect(() => {
        getFilteredLanguage();
    }, [language, search]);
    return (
        <div ref={tooltipRef}>
            {currentLang && (
                <button
                    type="button"
                    className={`bg-transparent border-none flex  items-center ${
                        !doc ? "text-gray-500" : ""
                    }  use-tooltip-language`}
                    onClick={() => {
                        setShow(!show);
                    }}
                >
                    <img
                        alt="User Dropdown"
                        className="rounded-full w-6 h-6  object-fill my-auto mr-2"
                        src={`/dist/image/language/${language}.svg`}
                    />
                    <span
                        className={` ${
                            show_text ? "" : "hidden"
                        } md:!flex text-sm mt-[1px]`}
                    >
                        {language.toUpperCase()}
                    </span>
                </button>
            )}

            <Tooltip
                anchorSelect=".use-tooltip-language"
                // afterHide="animate__animated animate__fadeOutTopRight"

                place="bottom"
                events={["hover", "click"]}
                isOpen={show}
                className={`absolute !pointer-events-auto translate-y-[8%] md:!w-[250px] sm:!w-[215px] ${
                    showBoxShadow ? "!shadow-md" : ""
                }`}
                // opacity={1}
                // style={{
                //     zIndex: "100",
                //     backgroundColor: "white",
                //     position: "relative",
                // }}
                // border={`${showBoxShadow ? "1px solid #0000001f" : "none"}`}
            >
                <div>
                    <div className="relative w-full mb-4">
                        <div className="absolute  inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg
                                aria-hidden="true"
                                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                        </div>
                        <input
                            value={search}
                            type="text"
                            id="simple-search"
                            className="bg-transparent rounded-lg border border-gray-300 text-white text-sm focus:ring-orange-400 focus:border-orange-400 block w-full pl-10 p-2.5 "
                            placeholder={translate("DataTable", "Search")}
                            onChange={(e: any) => setSearch(e.target.value)}
                            required
                        />
                    </div>
                    <ul
                        className={`${
                            show_text
                                ? "!bg-white !text-black !mt-[-250px] left-[-131px] !w-[160px] absolute"
                                : "bg-white"
                        }  text-white p-2 overflow-y-scroll `}
                        style={{ maxHeight: "200px" }}
                    >
                        {filteredLanguage &&
                            filteredLanguage.map(
                                (item: Option, index: number) => (
                                    <li key={index}>
                                        <button
                                            onClick={() =>
                                                dispatch(
                                                    ChangeAppLanguage({
                                                        language:
                                                            item.value as Language,
                                                    }),
                                                )
                                            }
                                            className="font-medium text-black flex hover:text-[#DAA250] truncate overflow-hidden"
                                        >
                                            <img
                                                alt="User Dropdown"
                                                className="rounded-full w-6 h-6 object-fill my-auto mr-2"
                                                src={item.image}
                                            />{" "}
                                            {item.label}
                                        </button>
                                        <span className="my-1">
                                            <hr className="dropdown-divider border-white/[0.08]" />
                                        </span>
                                    </li>
                                ),
                            )}
                    </ul>
                </div>
            </Tooltip>
        </div>
    );
};

export default LanguageSwitcher;
