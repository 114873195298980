import React, { useMemo, useState } from "react";
import PhoneInput from "react-phone-input-2";
import translator from "../Extra/Translation/Translate";
import useAuthentication from "../../Services/Authentication/useAuthentication";
import {
    SendMailSupport,
    SendTelegramSupport,
} from "../../Services/Support/fab.service";
import alert_message from "../../Services/Alert/AlertMessage";
import InputField from "./inputfield";
import { KpMailIcon, KpTelegramIcon } from "../../Front/Icons/MosaicIcons";

interface FormProps {
    type: "email" | "telegram";
    toggleForm: () => void;
}

const Form: React.FC<FormProps> = ({ type, toggleForm }) => {
    const { translate } = translator();
    const { user } = useAuthentication();
    const [email, setEmail] = useState<string>(user?.email ? user.email : "");

    const [name, setName] = useState<string>(
        user?.first_name ? user.first_name + " " + user.last_name : "",
    );

    const [message, setMessage] = useState<string>("");

    const [phone, setPhone] = useState<string>(
        user?.phone ? user.phone_country_code + user.phone : "",
    );

    const onSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        if (type === "email") {
            const send = SendMailSupport({
                name: name,
                message: message,
                email: email,
            });

            alert_message.promise("Envoi en cours", send).then(() => {
                toggleForm();
            });
        } else {
            const send = SendTelegramSupport({
                name: name,
                message: message,
                phone: phone,
            });

            alert_message.promise("Envoi en cours", send).then(() => {
                setMessage("");
                toggleForm();
            });
        }
    };

    const disableSubmit = useMemo(() => {
        return (
            !name ||
            name.length < 2 ||
            (type === "telegram" && (!phone || phone.length < 10)) ||
            (type === "email" && (!email || email.length < 5)) ||
            !message ||
            message.length < 10
        );
    }, [phone, name, email, message, type]);

    return (
        <form onSubmit={onSubmit}>
            <InputField
                id="nom"
                label={translate("SupportFaq", "YourName")}
                placeholder="Nom"
                value={name}
                onChange={(e) => setName(e.target.value)}
            />
            {type === "email" ? (
                <InputField
                    id="email"
                    label={translate("SupportFaq", "YourEmail")}
                    type="email"
                    placeholder="exemple@email.com"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                />
            ) : (
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        {translate("SupportFaq", "YourPhone")}
                    </label>
                    <PhoneInput
                        value={phone}
                        excludeCountries={[
                            "af",
                            "ir",
                            "iq",
                            "kp",
                            "ly",
                            "ss",
                            "sd",
                            "sy",
                            "ye",
                        ]}
                        countryCodeEditable={false}
                        enableLongNumbers={15}
                        enableSearch={true}
                        onChange={(phone) => {
                            setPhone(phone);
                            console.log({ phone });
                        }}
                        specialLabel={translate("Profil", "Phone_number")}
                    />
                </div>
            )}
            <InputField
                id="message"
                label={translate("SupportFaq", "Message")}
                type="textarea"
                placeholder={translate("SupportFaq", "YourMessage")}
                rows={4}
                onChange={(e) => setMessage(e.target.value)}
                value={message}
            />
            <button
                type="submit"
                disabled={disableSubmit}
                className="w-full bg-kalypay-100 flex gap-2 justify-center items-center text-white py-2 px-4 rounded-md transition-colors cursor-pointer disabled:bg-gray-300"
            >
                {type === "email" ? <KpMailIcon /> : <KpTelegramIcon />}
                {type === "email"
                    ? translate("SupportFaq", "SendViaEmail")
                    : translate("SupportFaq", "SendViaTelegram")}
            </button>
        </form>
    );
};

export default Form;
