import { NavLink, useLocation } from "react-router-dom";
import { useState } from "react";
import translator from "../../Components/Extra/Translation/Translate";
import {
    KpAssetIcon,
    MosaicDashboard,
    KpTransactionIcon,
    KpCreditCardIcon,
    KpColdWalletIcon,
    KpEcosystemIcon,
    KpPaymentIcon,
    KpIbanIcon,
    KpMarkerIcon,
    KpLoyaltyIcon,
    KpEarnIcon,
    KpSettingIcon,
    KpHelpIcon,
    KpLoanIcon,
    KpPartenarIcon,
    KpOrderIcon,
    KpUsersIcon,
    KpModerationIcon,
    KpVirementIcon,
    KpResComIcon,
    KpComIcon,
    KpPosIcon,
    KpServicesIcon,
    KpAMLIcon,
    KpMailIcon,
    KpTelegramIcon,
} from "../Icons/MosaicIcons";
import RoutesNames from "../../Services/RoutesNames";
import useAuthentication from "../../Services/Authentication/useAuthentication";
import { useSideMenu } from "../Provider/SideMenuProvider";
import { ChevronDown, ChevronUp } from "lucide-react";
import { useAppProvider } from "../Provider/AppProvider";
import { useFaqContext } from "../../Components/FAB/FaqContext";

export type ItemListProps = {
    name: string;
    icon: any;
    path?: string;
    redirectPath?: string;
    childs: ItemListProps[];
    allowed?: boolean;
    disabled?: boolean;
    hideIcon?: boolean;
    notif?: number;
    onClick?: () => void;
};

export type GroupedMenu = {
    label: string;
    menuList: ItemListProps[];
    allowed?: boolean;
};

const ItemListComponent = ({
    name,
    icon,
    childs,
    path,
    redirectPath,
    disabled = false,
    notif,
    hideIcon = false,
    onClick,
}: ItemListProps) => {
    const location = useLocation();

    const [expanded, setExpanded] = useState<boolean>(false);
    const { sidebarOpen, setSidebarOpen } = useAppProvider();

    const { translate } = translator();

    const toggleExanded = (e: any) => {
        if (childs && childs.length > 0) {
            setExpanded((expanded) => !expanded);
            e.preventDefault();
            return false;
        }
    };

    if (!path) {
        return (
            <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 `}>
                <button
                    onClick={() => {
                        if (onClick) onClick();
                        setSidebarOpen(false);
                    }}
                    className={`block  truncate transition duration-150  text-slate-400 hover:text-kalypay-700`}
                >
                    <div className="flex items-center">
                        {icon}
                        <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ">
                            {translate(".", name)}
                        </span>
                    </div>
                </button>
            </li>
        );
    }

    if (path.includes("http")) {
        return (
            <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 `}>
                <a
                    href={path}
                    target="_blank"
                    className={`block  truncate transition duration-150  text-slate-400 hover:text-kalypay-700`}
                >
                    <div className="flex items-center">
                        {icon}
                        <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ">
                            {translate(".", name)}
                        </span>
                    </div>
                </a>
            </li>
        );
    }

    return (
        <li
            className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                location.pathname.includes(path) &&
                "bg-slate-100 dark:bg-slate-900"
            }`}
        >
            {disabled ? (
                <NavLink
                    to={"#"}
                    className={`block  truncate transition duration-150 cursor-not-allowed  hover:text-kalypay-700 text-slate-400 ${
                        location.pathname.includes(path)
                            ? "hover:text-kalypay-700 dark:text-slate-200  dark:hover:text-kalypay-700"
                            : "hover:text-kalypay-700"
                    }`}
                >
                    <div className="flex items-center">
                        {hideIcon ? <></> : icon}
                        <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ">
                            {translate(".", name)}
                        </span>
                    </div>
                </NavLink>
            ) : (
                <>
                    {childs && childs.length > 0 ? (
                        <>
                            <button
                                className="flex text-slate-400 w-full cmo81 crp72 c5mjj c32al"
                                onClick={toggleExanded}
                            >
                                <div className="flex w-full   justify-between cmgwo">
                                    <div className="flex items-center">
                                        {icon}

                                        <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ">
                                            {translate(".", name)}
                                        </span>
                                    </div>

                                    <div className="flex 2xl:opacity-100 cjbqm cd0k1 cbunn ch8aq c7n6y c9o7o">
                                        {expanded ? (
                                            <ChevronUp />
                                        ) : (
                                            <ChevronDown />
                                        )}
                                    </div>
                                </div>
                            </button>
                            <div className="2xl:block cofpc cpbr8 caqfm px-2">
                                <ul
                                    className={`c5xk8 ctmd2 ml-3 mt-3  ${
                                        expanded ? "cylx5" : "hidden"
                                    }`}
                                >
                                    {childs.map((item_child, index) => {
                                        return (
                                            <ItemListComponent
                                                {...item_child}
                                                hideIcon
                                                key={index}
                                                onClick={item_child.onClick}
                                            />
                                        );
                                    })}
                                </ul>
                            </div>
                        </>
                    ) : (
                        <NavLink
                            to={redirectPath ? redirectPath : path}
                            target={path.includes("http") ? "_blank" : "_self"}
                            className={`block  truncate transition duration-150 hover:text-kalypay-700 text-slate-400 ${
                                location.pathname.includes(path)
                                    ? "hover:text-kalypay-700 dark:text-slate-200  dark:hover:text-kalypay-700"
                                    : "hover:text-kalypay-700"
                            }`}
                            onClick={() => {
                                setSidebarOpen(false);
                            }}
                        >
                            <div className="flex items-center">
                                {hideIcon ? <></> : icon}
                                <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ">
                                    {translate(".", name)}
                                </span>
                                {notif !== undefined && notif > 0 && (
                                    <div className="flex flex-shrink-0 ml-2">
                                        <span className="inline-flex items-center justify-center h-5 text-xs font-medium text-white bg-kalypay-500 px-2 rounded">
                                            {notif}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </NavLink>
                    )}
                </>
            )}
        </li>
    );
};

const GroupeMenuComponent = ({ groups }: { groups: GroupedMenu[] }) => {
    return (
        <>
            {groups
                .filter((group) => group.allowed !== false)
                .map((group, index) => (
                    <div key={`menu-group-${index}`}>
                        <h3 className="text-xs uppercase text-slate-500 font-semibold pl-3">
                            <span
                                className="hidden lg:block lg:sidebar-expanded:hidden 2xl:hidden xl:hidden text-center w-6"
                                aria-hidden="true"
                            >
                                •••
                            </span>
                            <span className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                                {group.label}
                            </span>
                        </h3>
                        <ul className="mt-3">
                            {group.menuList.map((menu, i) =>
                                menu.allowed === undefined || menu.allowed ? (
                                    <ItemListComponent
                                        key={i}
                                        name={menu.name}
                                        icon={menu.icon}
                                        path={menu.path}
                                        redirectPath={menu.redirectPath}
                                        childs={menu.childs}
                                        disabled={menu.disabled}
                                        notif={menu.notif}
                                        onClick={menu.onClick}
                                    />
                                ) : null,
                            )}
                        </ul>
                    </div>
                ))}
        </>
    );
};

const SideMenu = () => {
    const location = useLocation();
    const { translate } = translator();
    const {
        isAdmin,
        isFinancialAdmin,
        isMerchant,
        isSaleManager,
        isCommercial,
        isPos,
        isAdvisor,
        isAgent,
        isSU,
        isBusnessDeveloper,
    } = useAuthentication();

    const { notiifcation } = useSideMenu();
    const { setChatType } = useFaqContext();

    const AccountMenu: GroupedMenu[] = [
        {
            label: translate("ACCOUNT_MENU", "MANAGE"),
            menuList: [
                {
                    name: "ACCOUNT_MENU.ASSETS",
                    path: RoutesNames.Assets,
                    icon: (
                        <KpAssetIcon
                            active={location.pathname.includes("assets")}
                        />
                    ),
                    childs: [],
                },
                {
                    name: "ACCOUNT_MENU.TRANSACTIONS",
                    path: RoutesNames.Transactions,
                    icon: (
                        <KpTransactionIcon
                            active={location.pathname.includes("transactions")}
                        />
                    ),
                    childs: [],
                },
                {
                    name: "ACCOUNT_MENU.PAYMENT",
                    path: RoutesNames.Payment,
                    icon: (
                        <KpPaymentIcon
                            active={location.pathname.includes("payment")}
                        />
                    ),
                    childs: [],
                    // disabled: true,
                },
                {
                    name: "ACCOUNT_MENU.ECOSYSTEM",
                    path: RoutesNames.Ecosystem.Index,
                    redirectPath: RoutesNames.Ecosystem.Perfectnodes,
                    icon: (
                        <KpEcosystemIcon
                            active={location.pathname.includes("ecosystem")}
                        />
                    ),
                    childs: [],
                },
                // {
                //     name: "ACCOUNT_MENU.COLDWALLET",
                //     path: RoutesNames.Coldwallet.Index,
                //     icon: (
                //         <KpEcosystemIcon
                //             active={location.pathname.includes("cold-wallet")}
                //         />
                //     ),
                //     childs: [],
                // },
            ],
        },
        {
            label: translate("ACCOUNT_MENU", "FINANCES"),
            menuList: [
                {
                    name: "ACCOUNT_MENU.CARDS",
                    path: RoutesNames.Cards,
                    icon: (
                        <KpCreditCardIcon
                            active={location.pathname.includes("cards")}
                        />
                    ),
                    childs: [],
                },
                {
                    name: "ACCOUNT_MENU.COLD_WALLET",
                    path: RoutesNames.ColdWallet,
                    icon: (
                        <KpColdWalletIcon
                            active={location.pathname.includes("cold-wallet")}
                        />
                    ),
                    childs: [],
                    // disabled: true,
                    allowed: false,
                },
                {
                    name: "NAV_SIDE.IBAN",
                    path: RoutesNames.Iban,
                    icon: (
                        <KpIbanIcon
                            active={location.pathname.includes("iban")}
                        />
                    ),
                    childs: [],
                    disabled: true,
                    allowed: false,
                },
                {
                    name: "Earning",
                    path: RoutesNames.Earning,
                    icon: (
                        <KpEarnIcon
                            active={location.pathname.includes(
                                RoutesNames.Earning,
                            )}
                        />
                    ),
                    childs: [
                        {
                            name: "ACCOUNT_MENU.EARNING",
                            path: RoutesNames.Lending,
                            icon: (
                                <KpEarnIcon
                                    active={location.pathname.includes(
                                        RoutesNames.Earning,
                                    )}
                                />
                            ),
                            childs: [],
                        },
                        {
                            name: "Nodes",
                            path: RoutesNames.Nodes,
                            icon: (
                                <KpEarnIcon
                                    active={location.pathname.includes(
                                        RoutesNames.Earning,
                                    )}
                                />
                            ),
                            childs: [],
                        },
                        {
                            name: "Staking",
                            path: RoutesNames.Trading,
                            icon: (
                                <KpEarnIcon
                                    active={location.pathname.includes(
                                        RoutesNames.Trading,
                                    )}
                                />
                            ),
                            childs: [],
                        },
                        {
                            name: "INVESTISMENT.MENU",
                            // name: "ACCOUNT_MENU.EARNING",
                            path: RoutesNames.Services,
                            icon: (
                                <KpServicesIcon
                                    active={location.pathname.includes(
                                        RoutesNames.Services,
                                    )}
                                />
                            ),
                            childs: [],
                        },
                    ],
                },
                {
                    name: "ACCOUNT_MENU.LOAN",
                    path: RoutesNames.Loan,
                    icon: (
                        <KpLoanIcon
                            active={location.pathname.includes("borrow")}
                        />
                    ),
                    childs: [],
                    // allowed: isAdmin || isSU,
                },
                {
                    name: "AML / KYT",
                    path: RoutesNames.AmlCheck,
                    icon: (
                        <KpAMLIcon
                            active={location.pathname.includes("borrow")}
                        />
                    ),
                    childs: [],
                    // allowed: isAdmin || isSU,
                },
            ],
        },
        {
            label: translate("ACCOUNT_MENU", "OTHER"),
            menuList: [
                {
                    name: "ACCOUNT_MENU.BUSINESS_DEV",
                    path: RoutesNames.Business_dev,
                    icon: (
                        <KpResComIcon
                            active={location.pathname.includes(
                                RoutesNames.Business_dev,
                            )}
                        />
                    ),
                    childs: [],
                    allowed: isAdmin || isSU || isFinancialAdmin,
                },
                {
                    name: "NAV_SIDE.Blocked_users",
                    path: RoutesNames.BloquedUsers,
                    icon: (
                        <KpResComIcon
                            active={location.pathname.includes(
                                RoutesNames.Business_dev,
                            )}
                        />
                    ),
                    childs: [],
                    allowed: isAdmin || isSU || isAdvisor,
                },
                {
                    name: "ACCOUNT_MENU.SALES_MANAGER",
                    path: RoutesNames.Sales_manager,
                    icon: (
                        <KpResComIcon
                            active={location.pathname.includes(
                                RoutesNames.Sales_manager,
                            )}
                        />
                    ),
                    childs: [],
                    allowed: isAdmin || isSU || isFinancialAdmin,
                },
                {
                    name: "ACCOUNT_MENU.COMMERCIAL",
                    path: RoutesNames.Commercial,
                    icon: (
                        <KpComIcon
                            active={location.pathname.includes(
                                RoutesNames.Commercial,
                            )}
                        />
                    ),
                    childs: [],
                    allowed:
                        isAdmin ||
                        // isAgent ||
                        isSU ||
                        isSaleManager ||
                        isFinancialAdmin,
                },
                {
                    name: "ACCOUNT_MENU.POINT_OF_SALE",
                    path: RoutesNames.Point_of_sale,
                    icon: (
                        <KpPosIcon
                            active={location.pathname.includes(
                                RoutesNames.Point_of_sale,
                            )}
                        />
                    ),
                    childs: [],
                    allowed:
                        isAdmin ||
                        // isAgent ||
                        isSU ||
                        isSaleManager ||
                        isFinancialAdmin ||
                        isCommercial,
                },
                {
                    name: "ACCOUNT_MENU.Customer",
                    path: RoutesNames.Customer,
                    icon: (
                        <KpUsersIcon
                            active={location.pathname.includes("customer")}
                        />
                    ),
                    childs: [],
                    allowed:
                        isAdmin ||
                        isAgent ||
                        isSU ||
                        isSaleManager ||
                        isFinancialAdmin ||
                        isCommercial ||
                        isAdvisor ||
                        isBusnessDeveloper,
                },
                {
                    name: "ACCOUNT_MENU.CARD_ORDER",
                    path: RoutesNames.CardOrder,
                    icon: (
                        <KpOrderIcon
                            active={location.pathname.includes("/orders")}
                        />
                    ),
                    childs: [],
                    notif: notiifcation.card_order,
                    allowed:
                        isAdmin ||
                        isFinancialAdmin ||
                        isCommercial ||
                        isSaleManager,
                },
                {
                    name: "ACCOUNT_MENU.DEPOSIT_VIREMENT",
                    path: RoutesNames.DepositVirementFA,
                    icon: (
                        <KpVirementIcon
                            active={location.pathname.includes(
                                RoutesNames.DepositVirementFA,
                            )}
                        />
                    ),
                    childs: [],
                    notif: notiifcation.virement,
                    allowed: isAdmin || isFinancialAdmin,
                },
                {
                    name: "ACCOUNT_MENU.MODERATION",
                    path: RoutesNames.Moderation,
                    icon: (
                        <KpModerationIcon
                            active={location.pathname.includes(
                                RoutesNames.Moderation,
                            )}
                        />
                    ),
                    childs: [],
                    notif: notiifcation.moderation,
                    allowed:
                        isAdmin || isSU || isSaleManager || isFinancialAdmin,
                },
                {
                    name: "ACCOUNT_MENU.MAIL_MODERATION",
                    path: RoutesNames.MailModeration,
                    icon: (
                        <KpModerationIcon
                            active={location.pathname.includes(
                                RoutesNames.MailModeration,
                            )}
                        />
                    ),
                    childs: [],
                    notif: notiifcation.mail_moderation,
                    allowed: isAdmin || isAdvisor,
                },
                {
                    name: "NAV_SIDE.Transaction_moderation",
                    path: RoutesNames.TransactionModeration,
                    icon: (
                        <KpModerationIcon
                            active={location.pathname.includes(
                                RoutesNames.TransactionModeration,
                            )}
                        />
                    ),
                    childs: [],
                    notif: notiifcation.transation_moderation,
                    allowed: isAdmin || isAdvisor || isFinancialAdmin,
                },
            ],
            allowed:
                isAdmin ||
                isAdvisor ||
                isFinancialAdmin ||
                isAdvisor ||
                isBusnessDeveloper ||
                isCommercial ||
                isSaleManager,
        },
        {
            label: translate("ACCOUNT_MENU", "SUPPORT"),
            menuList: [
                {
                    name: "ACCOUNT_MENU.LOYALTY",
                    path: RoutesNames.Loaylty,
                    icon: (
                        <KpLoyaltyIcon
                            active={location.pathname.includes("loyalty")}
                        />
                    ),
                    childs: [],
                },
                {
                    name: "ACCOUNT_MENU.FIND_AGENT",
                    path: RoutesNames.FindAgent,
                    icon: (
                        <KpMarkerIcon
                            active={location.pathname.includes("find-agent")}
                        />
                    ),
                    childs: [],
                    allowed: false,
                },
                {
                    name: "ACCOUNT_MENU.PARTENAR",
                    path: RoutesNames.Partners,
                    icon: (
                        <KpPartenarIcon
                            active={location.pathname.includes("partners")}
                        />
                    ),
                    childs: [],
                    allowed: false,
                },
                {
                    name: "FAQ",
                    path: RoutesNames.Faq,
                    icon: (
                        <KpHelpIcon
                            active={location.pathname.includes("faq")}
                        />
                    ),
                    childs: [],
                },
                {
                    name: "Telegram",
                    icon: (
                        <KpTelegramIcon
                            active={location.pathname.includes("faq")}
                        />
                    ),
                    childs: [],
                    allowed: true,
                    // onClick: () => setChatType("telegram")
                    path: "https://t.me/kalypay",
                },
                {
                    name: "Mail",
                    icon: <KpMailIcon />,
                    childs: [],
                    allowed: true,
                    onClick: () => setChatType("email"),
                },
                {
                    name: "ACCOUNT_MENU.SECURTIY_AND_SETTING",
                    path: RoutesNames.Setting.Index,
                    redirectPath: RoutesNames.Setting.General,
                    icon: (
                        <KpSettingIcon
                            active={location.pathname.includes("settings")}
                        />
                    ),
                    childs: [],
                },
            ],
        },
    ];

    return <GroupeMenuComponent groups={AccountMenu} />;
};

export default SideMenu;
