import React from "react";

import KpCopyClick from "../../Components/Extra/Bouton/KpCopyClick";
import translator from "../../Components/Extra/Translation/Translate";
import Banner from "../Layout/Banner";
import SecondaryNav from "../Layout/SecondaryNav";
import SubTitle, { SubTitle2, SubTitle3 } from "../Layout/SubTitle";
import SidebarLink from "../Layout/SidebarLink";
import { ArrowLeft } from "lucide-react";

const Integration = () => {
    const { translate } = translator();
    return (
        <>
            <span className="flex md:hidden items-center space-x-2 mb-5 text-kalypay-400">
                <ArrowLeft size={20} />
                <SidebarLink href="/documentation/introduction">
                    {translate("DOC_API_PAGE", "DOCUMENTATION")}
                </SidebarLink>
            </span>
            <Banner title={translate("DOC_API_PAGE", "PAYMENT")}></Banner>
            <article className="flex xl:space-x-12">
                <div className="min-w-0">
                    <div className="md:hidden flex items-center mb-8">
                        <div className="flex items-center text-sm whitespace-nowrap min-w-0 ml-3">
                            <span className="text-slate-600 dark:text-slate-400">
                                Documentation
                            </span>
                            <svg
                                className="fill-slate-400 shrink-0 mx-2 dark:fill-slate-500"
                                width="8"
                                height="10"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M1 2 2.414.586 6.828 5 2.414 9.414 1 8l3-3z"></path>
                            </svg>
                            <span className="text-slate-800 font-medium truncate dark:text-slate-200">
                                Fundamentals
                            </span>
                        </div>
                    </div>
                    <div>
                        <header className="mb-6">
                            <h1
                                id="pay-with"
                                data-scrollspy-target=""
                                className="h2 text-slate-800 mb-4 dark:text-slate-200"
                            >
                                {translate("DOC_API_PAGE", "API_Service")}
                            </h1>
                            <p className="text-lg text-slate-600 dark:text-slate-400">
                                {translate(
                                    "DOC_API_PAGE",
                                    "API_Service_Description",
                                )}
                            </p>
                        </header>
                        <div className="text-slate-600 dark:text-slate-400 space-y-6">
                            <div className="space-y-6">
                                <div className="space-y-4">
                                    <SubTitle id="base-url" title="Base url" />
                                    <p>
                                        {translate(
                                            "DOC_API_PAGE",
                                            "API_Base_Url_Description",
                                        )}
                                    </p>

                                    <pre className="overflow-x-auto text-sm text-slate-400 bg-slate-800 border border-slate-700 p-4 rounded">
                                        <code className="font-pt-mono flex justify-between">
                                            <span className="text-purple-500">
                                                https://api.kalypay.com/payment
                                            </span>
                                            <span className="text-teal-500">
                                                <KpCopyClick
                                                    text="https://api.kalypay.com/payment"
                                                    message=""
                                                />
                                            </span>{" "}
                                        </code>
                                    </pre>
                                </div>
                            </div>

                            <div className="space-y-6 pt-5">
                                <div className="space-y-4">
                                    <SubTitle
                                        id="initialize-payment"
                                        title={translate(
                                            "DOC_API_PAGE",
                                            "API_Initialize_Payment",
                                        )}
                                    />

                                    <p>
                                        {translate(
                                            "DOC_API_PAGE",
                                            "API_Initialize_Payment_Description",
                                        )}
                                    </p>
                                    <SubTitle2 title="Endpoint"></SubTitle2>
                                    <pre className="overflow-x-auto text-sm text-slate-400 bg-slate-800 border border-slate-700 p-4 rounded">
                                        <div className="flex justify-between">
                                            <div>
                                                <code className="font-pt-mono  text-lg font-[700] text-purple-500 text-">
                                                    POST
                                                </code>{" "}
                                                <code className="font-pt-mono ">
                                                    https://api.kalypay.com/payment/initialize
                                                </code>
                                            </div>
                                            <span className="text-teal-500">
                                                <KpCopyClick
                                                    text="https://api.kalypay.com/payment/initialize"
                                                    message=""
                                                />
                                            </span>{" "}
                                        </div>
                                    </pre>
                                    <SubTitle2 title="Request Header"></SubTitle2>
                                    <pre className="overflow-x-auto text-sm text-slate-400 bg-slate-800 border border-slate-700 p-4 rounded">
                                        <code className="font-pt-mono block">
                                            {"{"}
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "Content-Type": "application/json",
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "App-id": "YOUR_APP_ID",
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "App-key": "YOUR_APP_KEY",
                                        </code>
                                        <code className="font-pt-mono block">
                                            {"}"}
                                        </code>
                                    </pre>
                                    <SubTitle2 title="Request Body"></SubTitle2>
                                    <pre className="overflow-x-auto text-sm text-slate-400 bg-slate-800 border border-slate-700 p-4 rounded">
                                        <code className="font-pt-mono block">
                                            {"{"}
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "transaction_id": "INF-1111",
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "wallet": "USD",
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "amount": 100,
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "currency": "USD",
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "call_back_url": "string",
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "success_url": "string",
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "fail_url": "string",
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "description": "First Payment",
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "payee_name": "madigi",
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "description": "First Payment",
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "reference": "#order-1"
                                        </code>
                                        <code className="font-pt-mono block">
                                            {"}"}
                                        </code>
                                    </pre>
                                    <SubTitle2 title="Response"></SubTitle2>
                                    <SubTitle3 title="200 OK"></SubTitle3>
                                    <pre className="overflow-x-auto text-sm text-slate-400 bg-slate-800 border border-slate-700 p-4 rounded">
                                        <code className="font-pt-mono block">
                                            {"{"}
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "payment_url":
                                            "https://..?ref=order-token",
                                            //redirect the user on this payment
                                            page
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "token": "xxxx-xxxx-x-x--xx--x", //
                                            reference of the order on Kalyssi
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "code": PAYMENT_INITIALIZED,
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "status": "ok",
                                        </code>
                                        <code className="font-pt-mono block">
                                            {"}"}
                                        </code>
                                    </pre>
                                    <SubTitle3 title="401 Unauthorized"></SubTitle3>
                                    <pre className="overflow-x-auto text-sm text-slate-400 bg-slate-800 border border-slate-700 p-4 rounded">
                                        <code className="font-pt-mono block">
                                            {"{"}
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "code": INVALID_MERCHANT,
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "message": "Merchant invalid",
                                        </code>
                                        <code className="font-pt-mono block">
                                            {"}"}
                                        </code>
                                    </pre>
                                    <SubTitle3 title="422 Unprocessable Entity"></SubTitle3>
                                    <pre className="overflow-x-auto text-sm text-slate-400 bg-slate-800 border border-slate-700 p-4 rounded">
                                        <code className="font-pt-mono block">
                                            {"{"}
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "code": INVALID_WALLET,
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "message": "Invalid wallet",
                                        </code>
                                        <code className="font-pt-mono block">
                                            {"}"}
                                        </code>
                                    </pre>
                                    <SubTitle3 title="403 Forbidden"></SubTitle3>
                                    <pre className="overflow-x-auto text-sm text-slate-400 bg-slate-800 border border-slate-700 p-4 rounded">
                                        <code className="font-pt-mono block">
                                            {"{"}
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "code": DISABLED_SERVICE,
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "message": "Payemnt is disabled,
                                            please enable it on your profile.",
                                        </code>
                                        <code className="font-pt-mono block">
                                            {"}"}
                                        </code>
                                    </pre>
                                    <pre className="overflow-x-auto text-sm text-slate-400 bg-slate-800 border border-slate-700 p-4 rounded">
                                        <code className="font-pt-mono block">
                                            {"{"}
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "code": INACTIVE_MERCHAND,
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "message": "Merchant status has been
                                            desactivated.",
                                        </code>
                                        <code className="font-pt-mono block">
                                            {"}"}
                                        </code>
                                    </pre>
                                </div>
                            </div>

                            <div className="space-y-6">
                                <div className="space-y-4">
                                    <SubTitle
                                        id="check-status"
                                        title={translate(
                                            "DOC_API_PAGE",
                                            "API_Check_Status",
                                        )}
                                    />
                                    <p>
                                        {translate(
                                            "DOC_API_PAGE",
                                            "API_Check_Status_Description",
                                        )}
                                    </p>
                                    <SubTitle2 title="Endpoint"></SubTitle2>
                                    <pre className="overflow-x-auto text-sm text-slate-400 bg-slate-800 border border-slate-700 p-4 rounded">
                                        <div className="flex justify-between">
                                            <div>
                                                <code className="font-pt-mono  text-lg font-[700] text-purple-500 text-">
                                                    POST
                                                </code>{" "}
                                                <code className="font-pt-mono ">
                                                    https://api.kalypay.com/payment/check-status
                                                </code>
                                            </div>
                                            <span className="text-teal-500">
                                                <KpCopyClick
                                                    text="https://api.kalypay.com/payment/check-status"
                                                    message=""
                                                />
                                            </span>{" "}
                                        </div>
                                    </pre>
                                    <SubTitle2 title="Request Header"></SubTitle2>
                                    <pre className="overflow-x-auto text-sm text-slate-400 bg-slate-800 border border-slate-700 p-4 rounded">
                                        <code className="font-pt-mono block">
                                            {"{"}
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "Content-Type": "application/json",
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "App-id": "YOUR_APP_ID",
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "App-key": "YOUR_APP_KEY",
                                        </code>
                                        <code className="font-pt-mono block">
                                            {"}"}
                                        </code>
                                    </pre>
                                    <SubTitle2 title="Request Body"></SubTitle2>
                                    <pre className="overflow-x-auto text-sm text-slate-400 bg-slate-800 border border-slate-700 p-4 rounded">
                                        <code className="font-pt-mono block">
                                            {"{"}
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "transaction_id":
                                            "YOUR_UNIQUE_TRANSACTION_ID",
                                        </code>
                                        <code className="font-pt-mono block">
                                            {"}"}
                                        </code>
                                    </pre>
                                    <SubTitle3 title="200 OK"></SubTitle3>
                                    <pre className="overflow-x-auto text-sm text-slate-400 bg-slate-800 border border-slate-700 p-4 rounded">
                                        <code className="font-pt-mono block">
                                            {"{"}
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "transaction_id":
                                            "YOUR_UNIQUE_TRANSACTION_ID",
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "status": "completed" //{" "}
                                            <span className="text-green-600">
                                                "completed" | "pending" |
                                                "canceled" | "expired"
                                            </span>
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "transaction":{"{"}
                                            <code className="font-pt-mono block ml-5">
                                                "transaction_id":
                                                "YOUR_UNIQUE_TRANSACTION_ID",
                                            </code>
                                            <code className="font-pt-mono block ml-5">
                                                "status": "completed" //{" "}
                                                <span className="text-green-600">
                                                    "completed" | "pending" |
                                                    "canceled" | "expired"
                                                </span>
                                            </code>
                                            <code className="font-pt-mono block ml-5">
                                                "token": "xxxx-xxxx-x-x--xx--x",
                                                // reference of the order on
                                                Kalyssi
                                            </code>
                                            <code className="font-pt-mono block ml-5">
                                                "wallet": "USD",
                                            </code>
                                            <code className="font-pt-mono block ml-5">
                                                "amount": 100,
                                            </code>
                                            <code className="font-pt-mono block ml-5">
                                                "currency": "USD",
                                            </code>
                                            <code className="font-pt-mono block ml-5">
                                                "reference": "#order-1"
                                            </code>
                                            {"}"}
                                        </code>
                                        <code className="font-pt-mono block">
                                            {"}"}
                                        </code>
                                    </pre>
                                    <SubTitle3 title="422 Unprocessable Entity"></SubTitle3>
                                    <pre className="overflow-x-auto text-sm text-slate-400 bg-slate-800 border border-slate-700 p-4 rounded">
                                        <code className="font-pt-mono block">
                                            {"{"}
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "code": INVALID_TRANSACTION,
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "message": "Invalid transaction",
                                        </code>
                                        <code className="font-pt-mono block">
                                            {"}"}
                                        </code>
                                    </pre>
                                </div>
                            </div>

                            <div className="space-y-6 mb-10">
                                <div className="space-y-4">
                                    <SubTitle
                                        id="get-fee-and-available-wallets"
                                        title={translate(
                                            "DOC_API_PAGE",
                                            "API_Get_Fee_And_Available_Wallets",
                                        )}
                                    />
                                    <p>
                                        {translate(
                                            "DOC_API_PAGE",
                                            "API_Get_Fee_And_Available_Wallets_Description",
                                        )}
                                    </p>
                                    <SubTitle2 title="Endpoint"></SubTitle2>
                                    <pre className="overflow-x-auto text-sm text-slate-400 bg-slate-800 border border-slate-700 p-4 rounded">
                                        <div className="flex justify-between">
                                            <div>
                                                <code className="font-pt-mono  text-lg font-[700] text-purple-500 text-">
                                                    GET
                                                </code>{" "}
                                                <code className="font-pt-mono ">
                                                    https://api.kalypay.com/payment
                                                </code>
                                            </div>
                                            <span className="text-teal-500">
                                                <KpCopyClick
                                                    text=" https://api.kalypay.com/payment"
                                                    message=""
                                                />
                                            </span>{" "}
                                        </div>
                                    </pre>
                                    <SubTitle2 title="Request Header"></SubTitle2>
                                    <pre className="overflow-x-auto text-sm text-slate-400 bg-slate-800 border border-slate-700 p-4 rounded">
                                        <code className="font-pt-mono block">
                                            {"{"}
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "Content-Type": "application/json",
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "App-id": "YOUR_APP_ID",
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "App-key": "YOUR_APP_KEY",
                                        </code>
                                        <code className="font-pt-mono block">
                                            {"}"}
                                        </code>
                                    </pre>

                                    <SubTitle3 title="200 OK"></SubTitle3>
                                    <pre className="overflow-x-auto text-sm text-slate-400 bg-slate-800 border border-slate-700 p-4 rounded">
                                        <code className="font-pt-mono block">
                                            {"{"}
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "fee": 2,5 //in percent
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "assets": [
                                            <code className="font-pt-mono block ml-5">
                                                {"{"}
                                                <code className="font-pt-mono block ml-5">
                                                    "name":"Dollar",
                                                </code>
                                                <code className="font-pt-mono block ml-5">
                                                    "abbr":"USD", // wallet
                                                    identifier
                                                </code>
                                                <code className="font-pt-mono block ml-5">
                                                    "logo":"logo_url", // URL of
                                                    the logo on
                                                    https://api.kalypay.com/
                                                </code>
                                                {"}"}
                                            </code>
                                            ]
                                        </code>
                                        <code className="font-pt-mono block">
                                            {"}"}
                                        </code>
                                    </pre>
                                    <SubTitle3 title="401 Unauthorized"></SubTitle3>
                                    <pre className="overflow-x-auto text-sm text-slate-400 bg-slate-800 border border-slate-700 p-4 rounded">
                                        <code className="font-pt-mono block">
                                            {"{"}
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "code": INVALID_MERCHANT,
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "message": "Merchant invalid",
                                        </code>
                                        <code className="font-pt-mono block">
                                            {"}"}
                                        </code>
                                    </pre>
                                </div>
                            </div>
                            <div className="space-y-6">
                                <div className="space-y-4">
                                    <SubTitle id="webhook" title="Webhook" />
                                    <p>
                                        {translate(
                                            "DOC_API_PAGE",
                                            "API_Webhook_Description",
                                        )}
                                    </p>

                                    <SubTitle2 title="Data"></SubTitle2>
                                    <pre className="overflow-x-auto text-sm text-slate-400 bg-slate-800 border border-slate-700 p-4 rounded">
                                        <code className="font-pt-mono block">
                                            {"{"}
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "transaction_id":
                                            "YOUR_UNIQUE_TRANSACTION_ID",
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "status": "completed" //{" "}
                                            <span className="text-green-600">
                                                "completed" | "pending" |
                                                "canceled" | "expired"
                                            </span>
                                        </code>
                                        <code className="font-pt-mono block ml-5">
                                            "transaction":{"{"}
                                            <code className="font-pt-mono block ml-5">
                                                "transaction_id":
                                                "YOUR_UNIQUE_TRANSACTION_ID",
                                            </code>
                                            <code className="font-pt-mono block ml-5">
                                                "status": "completed" //{" "}
                                                <span className="text-green-600">
                                                    "completed" | "pending" |
                                                    "canceled" | "expired"
                                                </span>
                                            </code>
                                            <code className="font-pt-mono block ml-5">
                                                "token": "xxxx-xxxx-x-x--xx--x",
                                                // reference of the order on
                                                Kalyssi
                                            </code>
                                            <code className="font-pt-mono block ml-5">
                                                "wallet": "USD",
                                            </code>
                                            <code className="font-pt-mono block ml-5">
                                                "amount": 100,
                                            </code>
                                            <code className="font-pt-mono block ml-5">
                                                "currency": "USD",
                                            </code>
                                            <code className="font-pt-mono block ml-5">
                                                "reference": "#order-1"
                                            </code>
                                            {"}"}
                                        </code>
                                        <code className="font-pt-mono block">
                                            {"}"}
                                        </code>
                                    </pre>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <SecondaryNav />
            </article>
        </>
    );
};

export default Integration;
