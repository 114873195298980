import React, { useState } from "react";

type FaqContextType = {
    chatType: "email" | "telegram" | undefined;
    setChatType: (type: "email" | "telegram" | undefined) => void;
    show?: boolean;
};

const FaqContext = React.createContext<FaqContextType>({
    chatType: undefined,
    setChatType: () => {},
    show: false,
});

export default function FaqProvider({
    children,
    show,
}: {
    children: React.ReactNode;
    show?: boolean;
}) {
    const [chatType, setChatType] = useState<"email" | "telegram" | undefined>(
        undefined,
    );

    return (
        <FaqContext.Provider
            value={{
                chatType,
                setChatType,
                show,
            }}
        >
            {children}
        </FaqContext.Provider>
    );
}

export const useFaqContext = () => React.useContext(FaqContext);
